import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getFirestore, collection, collectionGroup, query, where, getDocs } from 'firebase/firestore';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';

import { Folder, Item, ItemMetadata, ItemSubtitle, SubtitleInfo } from '../types';
import { LoadingWrapper, StyledSpinner } from '../components/common';
import { FolderBreadcrumbs, FolderBreadcrumb, FolderBreadcrumbsProps, getFolderBreadcrumbs } from '../components/Breadcrumbs';
import { Link } from 'react-router-dom';
import { getYouTubeId } from '../utils';
import { Trans, useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  h1 {
    font-size: 22px;
  }
  h1, p.intro {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    padding: 1em 1em;
    margin: -1em -1em 0 -1em;
    
    a {
      color: #888;
    }
    
    img {
      vertical-align: bottom;
    }
  }
  
  .controls {
    background-color: #fff;
    padding: 0.5em 1em;
    margin: 0 -1em;
    border-top: 1px solid #888;
    border-bottom: 1px solid #888;
    @media (min-width: 1056px) {
      border-radius: 1em;
      border-left: 1px solid #888;
      border-right: 1px solid #888;
    }

    .would-like-to {
      font-size: 22px;
      font-family: 'Indie Flower', cursive;

      select {
        padding: 0.2em 0.4em;
        margin: 0 0.4em;
      }
    }

    .show-english, .show-current-language {
      font-size: 18px;
      font-family: 'Indie Flower', cursive;

      input {
        padding: 0.4em 0.8em;
        margin: 0 0.4em;
      }
    }
  }
  
  .result-summary {
    font-size: 16px;
    margin: 0 -1em;
    padding: 0.5em 1em;
  }
  
  .title {
    text-align: left;
    font-size: 16px;
    
    ul {
      margin-top: 0;
      margin-bottom: 0.2em;
    }
    
    > a {
      color: black;
      font-weight: bold;
    }
  }
  
  .links {
    display: flex;
    
    a {
      padding: 0 2px;
    }
  }
  
  .subtitles {
    max-width: 200px;
  }
  
  .lang {
    display: inline-block;
    font-size: 10px;
    background-color: #fff;
    padding: 0.5em;
    margin: 0.2em 0.3em;
    border: 1px solid #c90;
    border-radius: 8px;
  }
  
  table {
    border-collapse: collapse;
    thead {
      tr {
        background-color: unset !important;
        
        th {
          padding: 1em;
          text-align: start;
        }
      }
    }
    tr {
      &:nth-child(odd) {
        background-color: #fff;
      }
      
      td {
        padding: 1em;
        
        &.links {
          a {
            display: block;
            color: #333;
          }
        }
      }
    }
  }
`;

const ItemAuthor = styled.div`
  font-style: italic;
  font-size: 0.8em;
`;

type Row = Item & {
  sourceURL: string,
  amaraID: string,
  folderName: string,
  folderTab: string,
  folderBreadcrumbsProps: FolderBreadcrumbsProps,
  subtitles: Array<SubtitleInfo>,
  language: string,
};

const languageNamesInEnglish = Intl.DisplayNames && new Intl.DisplayNames(['en'], { type: 'language' });
/**
 * Used for internal code only. Always in English.
 * If you need the language name presentationally, use the LanguageName component.
 */
const languageNameInternal = (langCode: string) => {
  try {
    if (langCode.startsWith('en-x-autogen')) {
      return 'English (autogenerated)';
    }
    return languageNamesInEnglish && languageNamesInEnglish.of(langCode);
  } catch (e) {
    console.error(`No language for ${langCode}`);
    return 'Unknown language';
  }
};

const getLanguage = (breadcrumbs: FolderBreadcrumb[]) => {
  if (breadcrumbs.length > 1) {
    const name = breadcrumbs[0].name.toLowerCase();

    if (name.includes('français') || name.includes('francais')) {
      return 'fr';
    } else if (name.includes('español')) {
      return 'es';
    } else if (name.includes('việt')) {
      return 'vi';
    } else if (name.toLowerCase().includes('italiano')) {
      return 'it';
    } else if (name.toLowerCase().includes('deutsch')) {
      return 'de';
    }
  }
  return 'en';
};

const getResultsTransContext = (filterCurrentLanguage: boolean, onlyCurrentLanguage: boolean, onlyEnglishCompleted: boolean, selectedLanguage: string) => {
  const isSameLanguage = filterCurrentLanguage && onlyCurrentLanguage;
  const isEnglishCompleted = onlyEnglishCompleted && selectedLanguage !== 'en';

  if (isSameLanguage) {
    return 'sameLanguage';
  } else if (isEnglishCompleted) {
    return 'englishComplete';
  }
  // Any string not matching an existing context matches the default context (none).
  return '';
};

const LanguageName = ({ langCode }: { langCode: string }) => {
  const { t } = useTranslation('doNotTranslate');
  // TODO: This const is duplicated in preparation for when we implement switching languages.
  const langNamesInEnglish = Intl.DisplayNames && new Intl.DisplayNames(['en'], { type: 'language' });
  
  let langName = '';
  try {
    if (langCode.startsWith('en-x-autogen')) {
      langName = t('subtitles.languageTagName.englishAutogenerated');
    } else {
      langName = langNamesInEnglish?.of(langCode) ?? t('subtitles.languageTagName.unknownLanguage');
    }
  } catch (e) {
    console.error(`No language for ${langCode}`);
    langName = t('subtitles.languageTagName.unknownLanguage');
  }
  return <>{langName}</>;
};

export const LanguageCodeAndName = ({ langCode }: { langCode: string }) => {
  return <>{langCode} - <LanguageName langCode={langCode} /></>;
};

function SubtitleLangTags({ subtitles } : { subtitles: Array<SubtitleInfo> }) {
  return <>
    {subtitles.length ? subtitles.map((s) => (
      <span className="lang" title={s.updatedAt.toDate().toLocaleString()} key={s.language}>
        <LanguageCodeAndName langCode={s.language} />
      </span>
    )) : '-'}
  </>;
}

function Subtitles() {
  const { t } = useTranslation('doNotTranslate');
  const [loading, setLoading] = useState<boolean>(true);
  const [videos, setVideos] = useState<Array<Row>>([]);
  const [allVideos, setAllVideos] = useState<Array<Row>>([]);
  const [subtitleLanguages, setSubtitleLanguages] = useState<Array<string>>(['en']);
  const [contentLanguages, setContentLanguages] = useState<Array<string>>(['en']);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const [onlyEnglishCompleted, setOnlyEnglishCompleted] = useState<boolean>(true);
  const [onlyCurrentLanguage, setOnlyCurrentLanguage] = useState<boolean>(true);

  useEffect(() => {
    const remoteConfig = getRemoteConfig();
    fetchAndActivate(remoteConfig).then(() => {
      const subtitles = getValue(remoteConfig, 'subtitleLanguages');
      setSubtitleLanguages((JSON.parse(subtitles.asString()) as Array<string>).sort((a, b) => (languageNameInternal(a) || a).localeCompare(languageNameInternal(b) || b)));
      const content = getValue(remoteConfig, 'contentLanguages');
      setContentLanguages(JSON.parse(content.asString()));
    });
  }, []);

  useEffect(() => {
    const loadVideos = async () => {
      const firestore = getFirestore();

      const videosPromise = getDocs(query(
        collection(firestore, 'docs'),
        where('visibility', '==', 'public'),
        where('type', '==', 'video'),
      ));
      const subtitlesPromise = getDocs(query(collectionGroup(firestore, 'subtitles')));
      const metadataPromise = getDocs(query(collectionGroup(firestore, 'metadata')));
      const foldersPromise = getDocs(query(collection(firestore, 'folders'), where('visibility', '==', 'public')));
      const allSubtitles = (await subtitlesPromise).docs.map((subtitleSnapshot) => {
        const subtitleData: ItemSubtitle = subtitleSnapshot.data() as ItemSubtitle;
        return {
          language: subtitleData.language,
          updatedAt: subtitleData.updatedAt,
          itemId: subtitleSnapshot.ref.parent.parent?.id,
        };
      });
      const allMetadata = (await metadataPromise).docs.map((metaSnap) => {
        const metadata: ItemMetadata = metaSnap.data() as ItemMetadata;
        return { ...metadata, itemId: metaSnap.ref.parent.parent?.id };
      });
      const folders = (await foldersPromise).docs.map((folderSnap) => {
        const folderData = folderSnap.data() as Folder;
        return { ...folderData, id: folderSnap.id };
      });
      const rows: Array<Row> = (await videosPromise).docs.map((itemSnapshot) => {
        const itemData: Item = itemSnapshot.data() as Item;

        const itemMetadata = allMetadata.find((m) => m.itemId === itemSnapshot.id);
        const itemSubtitles = allSubtitles.filter((s) => s.itemId === itemSnapshot.id);

        const folder = folders.find((f) => f.id === itemData.folder);

        const breadcrumbs = getFolderBreadcrumbs(folders, folder?.id);
        const folderBreadcrumbsProps = {
          folderId: folder?.id,
          folders,
        };

        return {
          ...itemData,
          id: itemSnapshot.id,
          folderName: folder?.title || '',
          folderTab: folder?.tab || '',
          language: getLanguage(breadcrumbs),
          folderBreadcrumbsProps,
          subtitles: itemSubtitles,
          sourceURL: itemMetadata?.sourceURL || '',
          amaraID: itemMetadata?.amaraID || '',
        };
      });

      setAllVideos(rows);
      setLoading(false);
    };
    loadVideos();
  }, []);

  const filterCurrentLanguage = useMemo<boolean>(() => {
    return contentLanguages.includes(selectedLanguage);
  }, [contentLanguages, selectedLanguage]);

  useEffect(() => {
    let filteredVideos = allVideos.filter(v => !v.subtitles.some(s => s.language === selectedLanguage));
    if (selectedLanguage !== 'en' && onlyEnglishCompleted) {
      filteredVideos = filteredVideos.filter(v => v.subtitles.some(s => s.language === 'en'));
    }
    if (filterCurrentLanguage && onlyCurrentLanguage) {
      filteredVideos = filteredVideos.filter(v => v.language === selectedLanguage);
    }
    filteredVideos.sort((v, v2) => v2.createdAt.seconds - v.createdAt.seconds);
    setVideos(filteredVideos);
  }, [allVideos, selectedLanguage, onlyEnglishCompleted, filterCurrentLanguage, onlyCurrentLanguage]);

  useEffect(() => {
    setOnlyCurrentLanguage(true);
    setOnlyEnglishCompleted(!filterCurrentLanguage);
  }, [selectedLanguage, filterCurrentLanguage]);

  const handleChangeLanguage = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedLanguage(e.target.value);
  }, []);

  const handleToggleEnglish = useCallback(() => {
    setOnlyEnglishCompleted(!onlyEnglishCompleted);
    setOnlyCurrentLanguage(onlyEnglishCompleted);
  }, [onlyEnglishCompleted]);

  const handleToggleCurrentLanguage = useCallback(() => {
    setOnlyCurrentLanguage(!onlyCurrentLanguage);
    setOnlyEnglishCompleted(onlyCurrentLanguage);
  }, [onlyCurrentLanguage]);

  if (loading) {
    return (
      <LoadingWrapper>
        <StyledSpinner />
        <p>{t('common.loading', { ns: 'web' })}</p>
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{t('subtitles.title')}</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link href="https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap" rel="stylesheet" />
      </Helmet>

      <p className='intro'>{t('subtitles.intro.title')}</p>
      <p className='intro'>
        <Trans
          ns="doNotTranslate"
          i18nKey="subtitles.intro.description"
          defaults={"Once you find a video you'd like to subtitle, click \"Contribute subtitles\" to see more detail and work on the subtitles. For more in depth instructions on volunteering to create subtitles, see <anchor>Contributing Subtitles for the Plum Village App</anchor"}
          components={{
            anchor: <a href='https://plumvillage.app/subtitles/' target='_blank' />,
          }}
        />
      </p>
      <div className='controls'>
        <p className='would-like-to'>
          <Trans
            ns="doNotTranslate"
            i18nKey="subtitles.controls.contributeToLanguageSelect"
            defaults="I would like to contribute subtitles in<selectLanguage/>"
            components={{
              selectLanguage: (
                <select onChange={handleChangeLanguage} value={selectedLanguage}>
                  {subtitleLanguages.map((l) => (
                    <option key={l} value={l}><LanguageName langCode={l} /></option>
                  ))}
                </select>
              ),
            }}
          />
        </p>
        {filterCurrentLanguage && (
          <p className='show-current-language'>
            <label>
              <input type='checkbox' checked={onlyCurrentLanguage} onChange={handleToggleCurrentLanguage}/>
              <Trans
                ns="doNotTranslate"
                i18nKey="subtitles.controls.filterByCurrentLanguage"
                defaults="Only show videos in the <language/> section of the app."
                components={{
                  language: <LanguageName langCode={selectedLanguage} />,
                }}
              />
            </label>
          </p>
        )}
        {selectedLanguage !== 'en' && (
          <p className='show-english'>
            <label>
              <input type='checkbox' checked={onlyEnglishCompleted} onChange={handleToggleEnglish}/>
              {t('subtitles.controls.filterByEnglishCompleted')}
            </label>
          </p>
        )}
      </div>

      <p className='result-summary'>
        <Trans
          ns="doNotTranslate"
          i18nKey="subtitles.resultSummary.videosCount"
          defaults="Showing {{count}} videos without <selectedLanguage/> subtitles."
          context={getResultsTransContext(filterCurrentLanguage, onlyCurrentLanguage, onlyEnglishCompleted, selectedLanguage)}
          values={{ count: videos.length }}
          components={{
            selectedLanguage: <LanguageName langCode={selectedLanguage} />,
          }}
        />
      </p>

      <table>
        <thead>
          <tr>
            <th>{t('subtitles.tableHeader.item')}</th>
            <th>{t('subtitles.tableHeader.subtitles')}</th>
            <th>{t('subtitles.tableHeader.links')}</th>
          </tr>
        </thead>
        <tbody>
            {videos.map((video) => (
              <tr key={video.id}>
                <td className='title'>
                  <FolderBreadcrumbs {...video.folderBreadcrumbsProps} />
                  <Link to={`/item/${video.slug}`}>{video.title}</Link>
                  {video.author && (
                    <ItemAuthor>
                      {t('itemView.byline', { ns: 'web', author: video.author })}
                    </ItemAuthor>
                  )}
                </td>
                <td className='subtitles'><SubtitleLangTags subtitles={video.subtitles} /></td>
                <td className='links'>
                  {(video.sourceURL && getYouTubeId(video.sourceURL)) ? (
                    <Link to={`/subs?yt=${getYouTubeId(video.sourceURL)}`} target='_blank'>{t('subtitles.contributeLinkText')}</Link>
                  ) : (
                    <small>{t('subtitles.subtitlingNotAvailable')}</small>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Wrapper>
  );
}

export default Subtitles;
