// Original: https://stackoverflow.com/a/62750096/174979
// Added TS to above to create this fn.
export const debounce = (fn: (...args: any[]) => any, delay = 1000) => {
  let timerId: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

export const getYouTubeId = (sourceUrl: string) => {
  let match = sourceUrl.match(/v=([^&]+)/);
  if (!match) {
    match = sourceUrl.match(/youtu\.be\/([^?]+)/);
  }
  if (!match) {
    match = sourceUrl.match(/shorts\/([^?]+)/);
  }
  return match && match[1];
};
