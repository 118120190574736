/**
 * Look into the datetimeFormat.ts file from the plumvillage-app repo
 * for more helpful utils to port over
 */
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export function monthDayYear(seconds: number): string {
  // In English, this is equivalent to "MMMM D, YYYY", in other languages the order may vary
  return dayjs.unix(seconds).format('LL');
}

export function fromNow(seconds: number): string {
  return dayjs.unix(seconds).fromNow();
}
