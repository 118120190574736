import firebase from 'firebase/compat/app';
import {
  Content, Folder, Item, ItemMetadata,
} from './types';

// Checks if a folder is visible in the hierarchy (all parents, grandparents, etc must be visible)
function isFolderVisible(folder: Folder, foldersForTab: Folder[]): boolean {
  if (folder.parent) {
    const parentFolder = foldersForTab.find((f) => f.id === folder.parent);
    if (!parentFolder) {
      // No visible parent folder found
      return false;
    }
    return isFolderVisible(parentFolder, foldersForTab);
  }

  return true;
}

export async function loadFoldersForTab(
  tab: string,
  onlyIfVisible = true,
): Promise<{ folders: Array<Folder>, offline: boolean }> {
  const foldersCollection = firebase.firestore().collection('folders');
  let query = foldersCollection.where('tab', '==', tab);
  if (onlyIfVisible) {
    query = query.where('visibility', '==', 'public');
  }
  const foldersSnap = await query.get();

  const foldersForTab: Array<Folder> = [];
  foldersSnap.forEach((folderSnap) => {
    foldersForTab.push(folderSnap.data() as Folder);
  });

  foldersForTab.sort((a, b) => a.order - b.order);

  const visibleFolders = foldersForTab.filter((f) => isFolderVisible(f, foldersForTab));

  return { folders: visibleFolders, offline: foldersSnap.metadata.fromCache };
}

export async function loadFolderBySlug(
  folderSlug: string,
  tab: string, // folders on different tabs may have same slug
  onlyIfVisible = true,
): Promise<Folder | null> {
  const foldersCollection = firebase.firestore().collection('folders');
  let query = foldersCollection.where('slug', '==', folderSlug).where('tab', '==', tab);
  if (onlyIfVisible) {
    query = query.where('visibility', '==', 'public');
  }
  const snap = await query.limit(1).get();
  let folder: Folder | null = null;
  snap.forEach((s) => {
    folder = s.data() as Folder;
  });
  return folder;
}

async function docsPrimaryFolderSnapshot(
  folderId: string,
  onlyIfVisible = true,
): Promise<Array<Item>> {
  const docsCollection = firebase.firestore().collection('docs');
  let query = docsCollection.where('folder', '==', folderId);
  if (onlyIfVisible) {
    query = query.where('visibility', '==', 'public');
  }
  const snap = await query.get();
  return snap.docs.map((itemSnap) => ({ id: itemSnap.id, ...itemSnap.data() } as Item));
}

async function docsSecondaryFolderSnapshot(
  folderId: string,
  onlyIfVisible = true,
): Promise<Array<Item>> {
  const docsCollection = firebase.firestore().collection('docs');
  const snap = await (onlyIfVisible
    ? docsCollection.where('visibility', '==', 'public').get()
    : docsCollection.get());
  return snap.docs
    .map((itemSnap) => ({ id: itemSnap.id, ...itemSnap.data() } as Item))
    .filter((itemSnap) => itemSnap.folders && itemSnap.folders[folderId]);
}

const sortCompareForFolder = (folderId: string) => (
  a: { folder?: string, order?: number, folders?: { [folderId: string]: { order?: number } } },
  b: { folder?: string, order?: number, folders?: { [folderId: string]: { order?: number } } },
) => {
  // return items with shortest order value first and those without any order property last
  const orderOptional1 = a.folder === folderId ? a.order : a.folders && a.folders[folderId]?.order;
  const order1 = typeof orderOptional1 === 'number' ? orderOptional1 : Number.MAX_VALUE;
  const orderOptional2 = b.folder === folderId ? b.order : b.folders && b.folders[folderId]?.order;
  const order2 = typeof orderOptional2 === 'number' ? orderOptional2 : Number.MAX_VALUE;
  return order1 - order2;
};

export async function loadDocsByFolder(
  folderId: string,
  onlyIfVisible = true,
): Promise<Array<Item>> {
  const primaryPromise = docsPrimaryFolderSnapshot(folderId, onlyIfVisible);
  const secondary = await docsSecondaryFolderSnapshot(folderId, onlyIfVisible);
  const primary = await primaryPromise;

  const items: Array<Item> = [...primary, ...secondary];

  items.sort(sortCompareForFolder(folderId));

  return items;
}

export async function loadItemBySlug(itemSlug: string, onlyIfVisible = true): Promise<Item | null> {
  const docsCollection = firebase.firestore().collection('docs');
  let query = docsCollection.where('slug', '==', itemSlug);
  if (onlyIfVisible) {
    query = query.where('visibility', '==', 'public');
  }
  const snap = await query.limit(1).get();
  let item: Item | null = null;
  snap.forEach((s) => {
    item = { id: s.id, ...s.data() } as Item;
  });
  return item;
}

export async function loadItemById(
  itemId: string,
): Promise<{ item: Item | null, offline: boolean }> {
  try {
    const snap = await firebase.firestore().collection('docs').doc(itemId).get();
    const item: Item | null = snap.exists ? { id: snap.id, ...snap.data() } as Item : null;
    return { item, offline: snap.metadata.fromCache };
  } catch (e) {
    // Probably the item does not exist (may have passed a slug instead of an ID)
    return { item: null, offline: false };
  }
}

export async function loadItemMetadataById(
  itemId: string,
): Promise<{ metadata: ItemMetadata | null, offline: boolean }> {
  try {
    const snap = await firebase.firestore()
      .collection('docs')
      .doc(itemId)
      .collection('metadata')
      .doc('metadata')
      .get();
    const metadata: ItemMetadata | null = snap.exists
      ? { id: snap.id, ...snap.data() } as Item : null;
    return { metadata, offline: snap.metadata.fromCache };
  } catch (e) {
    // The item does not exist or does not have metadata
    return { metadata: null, offline: false };
  }
}

function sortByOrder(a: { order: number }, b: { order: number }): number {
  if (a.order < b.order) {
    return -1;
  } if (a.order > b.order) {
    return 1;
  }
  return 0;
}

export async function fetchItems<T extends Item | Folder>(type: 'folders' | 'docs'): Promise<T[]> {
  return firebase.firestore()
    .collection(type).where('visibility', '==', 'public')
    .get()
    .then((querySnapshot) => {
      const items: T[] = [];
      let index = 0;
      querySnapshot.forEach((item) => {
        const data = item.data() as T;
        const order = typeof data.order === 'number' ? data.order : index;
        items.push({ ...data, id: item.id, order });
        index += 1;
      });

      const sortedItems: T[] = items.sort(sortByOrder);

      return sortedItems;
    });
}

export const itemsById = <T extends Item | Folder>(items: Array<T>): {
  [key: string]: T;
} => items.reduce((map, item) => {
    // Avoid reduce { ...spread } anti-pattern:
    // eslint-disable-next-line no-param-reassign
    map[item.id] = item;
    return map;
  }, {} as {
    [key: string]: T;
  });

export async function fetchContent(): Promise<Content> {
  const folders = await fetchItems<Folder>('folders');
  const docs = await fetchItems<Item>('docs');

  return {
    folders,
    docs,
    docsById: itemsById(docs),
    foldersById: itemsById(folders),
  };
}
