export type TabName = 'meditations' | 'resources' | 'talks';

interface Tab {
  slug: string,
  // Nested key under breadcrumbs. breadcrumbs.${i18nKey}
  i18nKey: TabName,
}

// eslint-disable-next-line import/prefer-default-export
export const TABS: { [key: string]: Tab } = {
  meditations: {
    slug: 'meditations',
    i18nKey: 'meditations',
  },
  talks: {
    slug: 'talks',
    i18nKey: 'talks',
  },
  resources: {
    slug: 'resources',
    i18nKey: 'resources',
  },
};

export const firebaseFunctionsBaseUrl = process.env.NODE_ENV === 'production'
  ? 'https://us-central1-plum-village.cloudfunctions.net'
  : 'http://localhost:5001/plum-village/us-central1';
