import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Listing, LoadingWrapper, StyledSpinner } from '../components/common';
import { Item } from '../types';
import ItemInList from '../components/ItemInList';
import { loadItemById } from '../dataAccess';
import amaraIcon from '../images/amara.png';
import { LanguageCodeAndName } from './Subtitles';
import { Trans, useTranslation } from 'react-i18next';


const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;

  h1 {
    font-size: 1rem;
  }
  
  a {
    color: #333;
  }

  .lang {
    display: inline-block;
    font-size: 10px;
    background-color: #fff;
    padding: 0.5em;
    margin: 0.2em 0.3em;
    border: 1px solid #c90;
    border-radius: 8px;
  }
`;

const Section = styled.div`
  margin: 2em 0;
  padding: 1em;
  background-color: #faf1eb;
  
  h3 {
    margin: 0 0 1em;
    text-align: left;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
  }
`;

function SubtitleLangTags({ languages }: { languages: Array<string> }) {
  const { t } = useTranslation('doNotTranslate');
  return <>
    {languages.length ? languages.map((l) => (
      <span className="lang" key={l}>
        <LanguageCodeAndName langCode={l} />
      </span>
    )) : t('subtitleLookup.noneMessage')}
  </>;
}

function SubtitleLookup() {
  const { t } = useTranslation('doNotTranslate');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [amaraId, setAmaraId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [matchedItem, setMatchedItem] = useState<Item | null>(null);
  const [amaraCreatedDate, setAmaraCreatedDate] = useState<Date | null>(null);
  const [videoName, setVideoName] = useState<string | null>(null);
  const [amaraLanguages, setAmaraLanguages] = useState<Array<string>>([]);
  const [amaraIncompleteLanguages, setAmaraIncompleteLanguages] = useState<Array<string>>([]);
  const [isOnPlumVillageAppYouTubeChannel, setIsOnPlumVillageAppYouTubeChannel] = useState(false);
  const [uploadingToAmara, setUploadingToAmara] = useState(false);

  const youTubeId = useMemo(() => searchParams.get('yt'), [searchParams]);

  useEffect(() => {
    // Reset state:
    setMatchedItem(null);
    setAmaraId(null);
    setLoading(true);
    setLoadingVideo(false);
    setAmaraCreatedDate(null);
    setVideoName(null);
    setAmaraLanguages([]);
    setAmaraIncompleteLanguages([]);
    setIsOnPlumVillageAppYouTubeChannel(false);
    setUploadingToAmara(false);

    if (!youTubeId) {
      navigate('/subtitles');
    }

    const getAmaraStatus = httpsCallable(getFunctions(), 'getAmaraStatus');
    getAmaraStatus({ youTubeId }).then((result) => {
      if (result.data) {
        const data = result.data as {
          amaraId: string | undefined,
          name: string | undefined,
          isOnAmara: boolean,
          itemId: string | undefined,
          createdAt: string | undefined,
          amaraLanguages: Array<string>,
          amaraIncompleteLanguages: Array<string>,
          isOnPlumVillageAppYouTubeChannel: boolean,
        };

        if (data.amaraId && data.isOnAmara) {
          setAmaraId(data.amaraId);
        }
        if (data.createdAt) {
          setAmaraCreatedDate(new Date(data.createdAt));
        }
        if (data.amaraLanguages) {
          setAmaraLanguages(data.amaraLanguages);
        }
        if (data.amaraIncompleteLanguages) {
          setAmaraIncompleteLanguages(data.amaraIncompleteLanguages);
        }
        if (data.name) {
          setVideoName(data.name);
        }
        if (data.isOnPlumVillageAppYouTubeChannel) {
          setIsOnPlumVillageAppYouTubeChannel(true);
        }

        if (data.itemId) {
          setLoadingVideo(true);
          // Load the item from Firestore
          loadItemById(data.itemId).then(({ item }) => {
            setMatchedItem(item);
            setLoadingVideo(false);
          });
        }
        setLoading(false);
      }
    });
  }, [youTubeId, navigate]);

  const handleUploadToAmara = useCallback(() => {
    const addVideoToAmara = httpsCallable(getFunctions(), 'addVideoToAmara');

    setUploadingToAmara(true);
    addVideoToAmara({ youTubeId }).then(() => {
      window.location.reload();
    });
  }, [youTubeId]);

  if (loading) {
    return (
      <LoadingWrapper>
        <StyledSpinner />
        <p>{t('common.loading', { ns: 'web' })}</p>
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{videoName ? t('subtitleLookup.title', { videoName }) : t('subtitleLookup.titleGeneral')}</title>
      </Helmet>

      <h1>{videoName ? t('subtitleLookup.subtitlesHeading', { videoName }) : t('subtitleLookup.subtitlesHeadingNoVideoName')}</h1>
      <p>
        <Trans
          ns="doNotTranslate"
          i18nKey="subtitleLookup.description"
          defaults="This page provides links to <anchor>contribute subtitles for Plum Village videos</anchor> on Amara, YouTube, and the Plum Village app."
          components={{
            anchor: <a href={'https://plumvillage.app/subtitles/'} target={'_blank'} />,
          }}
        />
      </p>

      <Section>
        <h3>{t('subtitleLookup.amara.heading')}</h3>
        {amaraId ? (
          <>
            <p>
              <small>{t('subtitleLookup.amara.availableText', { amaraCreatedDate: amaraCreatedDate?.toLocaleDateString() })}</small>
            </p>

            <p>
              <small>{t('subtitleLookup.amara.subtitlesCompletedLabel')}<SubtitleLangTags languages={amaraLanguages} /></small>
              {amaraIncompleteLanguages.length > 0 && (
                <>
                  <br />
                  <small>{t('subtitleLookup.amara.incompleteLabel')}<SubtitleLangTags languages={amaraIncompleteLanguages} /></small>
                </>
              )}
            </p>

            <p>
              <a href={`https://amara.org/videos/${amaraId}/`} target={'_blank'}>
                <img src={amaraIcon} alt={t('subtitleLookup.amara.title')} width={64} height={64} />
                <br />
                {t('subtitleLookup.amara.contributeText')}
              </a>
            </p>
          </>
        ) : (
          <>
            {isOnPlumVillageAppYouTubeChannel ? (
              <>
                <p>
                  {t('subtitleLookup.amara.videoNotYetAddedMessage')}
                </p>
                {uploadingToAmara ? <StyledSpinner /> : <button onClick={handleUploadToAmara}>{t('subtitleLookup.amara.addToAmaraButton')}</button>}
              </>
            ) : (
              <p>
                <Trans
                  ns="doNotTranslate"
                  i18nKey="subtitleLookup.amara.videoCannotBeAddedMessage"
                  defaults="This video has not yet been added to Amara. However, we cannot add it to Amara because it is not on the <anchor>Plum Village App YouTube channel</anchor>. If you believe we should add this video to Amara, please <anchor2>check with us</anchor2>. Alternatively, you should be <anchor3>able to manually add the video</anchor3> to Amara."
                  components={{
                    anchor: <a href={'https://www.youtube.com/plumvillageapp'} target={'_blank'} />,
                    anchor2: <a href={'https://sangha.plumvillage.app/c/subtitles/37'} target={'_blank'} />,
                    anchor3: <a href={'https://support.amara.org/support/solutions/articles/200448-add-videos-to-amara-public'} />,
                  }}
                />
              </p>
            )}
          </>
        )}
      </Section>

      <Section>
        <h3>{t('subtitleLookup.youtube.heading')}</h3>
        {amaraId || isOnPlumVillageAppYouTubeChannel ? (
          <>
            <iframe width="480" height="270" src={`https://www.youtube-nocookie.com/embed/${youTubeId}`} frameBorder="0" allow="encrypted-media" allowFullScreen></iframe>
            <p><small>{t('subtitleLookup.youtube.amaraSyncNote')}</small></p>
          </>
        ) : (
          <p>
            <Trans
              ns="doNotTranslate"
              i18nKey="subtitleLookup.youtube.channelNote"
              defaults="We can only show videos from the <anchor>Plum Village App YouTube channel.</anchor>"
              components={{
                anchor: <a href={'https://www.youtube.com/plumvillageapp'} target={'_blank'} />,
              }}
            />
          </p>
        )}
      </Section>

      <Section>
        <h3>{t('subtitleLookup.plumVillageApp.heading')}</h3>
        {loadingVideo ? (
          <StyledSpinner />
        ) : (
          matchedItem ? (
            <>
              <Listing>
                <ItemInList item={matchedItem} showBreadcrumbs={true} />
              </Listing>
              <p><small>{t('subtitleLookup.plumVillageApp.amaraSyncNote')}</small></p>
            </>
          ) : (
            <p>{t('subtitleLookup.plumVillageApp.videoNotFound')}<br/> <small>{t('subtitleLookup.plumVillageApp.videoNotFoundDetails')}</small></p>
          )
        )}
      </Section>

      <p>
        <Trans
          ns="doNotTranslate"
          i18nKey="subtitleLookup.plumVillageApp.subtitlesLink"
          defaults="Prefer to subtitle another video? See a list of <anchor>all videos needing subtitles</anchor> in the Plum Village App."
          components={{
            anchor: <Link to={'/subtitles'} />,
          }}
        />
      </p>
    </Wrapper>
  );
}

export default SubtitleLookup;
