import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import ky from 'ky';
import { useNavigate } from 'react-router-dom';

import { LoadingWrapper, StyledSpinner } from '../components/common';
import { firebaseFunctionsBaseUrl } from '../constants';
import { useTranslation } from 'react-i18next';

function Logout() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async () => {
      setLoading(false);

      await ky.post(`${firebaseFunctionsBaseUrl}/discourse-clearSession`, {
        json: { },
        credentials: 'include',
        mode: 'cors',
      });

      await signOut(auth);
      navigate('/login');
    });

    return unsubscribe;
  }, [navigate]);

  if (loading) {
    return (
      <LoadingWrapper>
        <StyledSpinner />
        <p>{t('logout.message')}</p>
      </LoadingWrapper>
    );
  }

  return (
    <p>{t('logout.redirectToLoginMessage')}</p>
  );
}

export default Logout;
