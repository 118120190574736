import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyChoJPE26zIMOYk8Iu2-n5LgZafZts20-M',
  authDomain: 'plum-village.firebaseapp.com',
  databaseURL: 'https://plum-village.firebaseio.com',
  projectId: 'plum-village',
  storageBucket: 'plum-village.appspot.com',
  messagingSenderId: '552620441503',
  appId: '1:552620441503:web:d9e002704cc2ee0e6647aa',
  measurementId: 'G-Y8WR1XC4BL',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

if (process.env.NODE_ENV !== 'production') {
  firebase.app().functions().useEmulator('localhost', 5001);
}
