import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Spinner from './Spinner';

export const LoadingWrapper = styled.div`
  margin-top: 200px;
  color: #e58a2f;
  text-align: center;
`;
export const StyledSpinner = styled(Spinner)`
  font-size: 24px;
`;

export const Listing = styled.ul`
  list-style: none;
  margin: 1em 0 1em 0;
  padding: 0;
`;

export const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: black;
  min-width: 280px;
  max-width: 36em;
`;
