import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Folder, Tab } from '../types';
import { TABS } from '../constants';
import { useTranslation } from 'react-i18next';

const BreadcrumbsContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

const Crumb = styled.li`
  display: inline-block;

  &::after {
    color: #777;
    font-size: small;
    content: '>';
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
  
  &:last-child::after {
    content: '';
  }
`;

const StyledLink = styled(Link)`
  color: #777;
  font-size: small;
`;

export type BreadcrumbKey = 'plumVillage' | 'meditations' | 'talks' | 'resources' | 'extras' | 'latestUpdates';

export interface Breadcrumb {
  path: string,
  i18nKey: BreadcrumbKey,
}

export interface FolderBreadcrumb {
  path: string,
  name: string,
}

export type BreadcrumbsProps = {
  tab: Tab;
  folderId: string | null | undefined;
  folders: Array<Folder>;
  skipTopLevel?: boolean;
  skipTab?: boolean;
};

type CustomBreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
};

export function CustomBreadcrumbs({ 
  breadcrumbs,
}: CustomBreadcrumbsProps) {
  const { t } = useTranslation();

  return (
    <BreadcrumbsContainer>
      {breadcrumbs.map((breadcrumb) => (
        <Crumb key={breadcrumb.path}>
          <StyledLink to={breadcrumb.path}>{t(`breadcrumbs.${breadcrumb.i18nKey}.name`)}</StyledLink>
        </Crumb>
      ))}
    </BreadcrumbsContainer>
  );
}

export function getAnyParentFolders(
  folders: Array<Folder>,
  folder: Folder,
): Array<Folder> {
  if (folder && folder.parent) {
    const parent = folders.find((f) => folder.parent === f.id);
    if (parent) {
      return [...getAnyParentFolders(folders, parent), parent];
    }
  }
  return [];
}

export const getFolderBreadcrumbs = (
  folders: Folder[],
  folderId: string | null | undefined,
): FolderBreadcrumb[] => {
  const folderBreadcrumbs = [];
  if (folderId) {
    const folder = folders.find((f) => f.id === folderId);
    if (folder) {
      const folderChain = [...getAnyParentFolders(folders, folder), folder];
      folderBreadcrumbs.push(...folderChain.map((f) => ({
        path: `/${f.tab}/${f.slug}`,
        name: f?.title || '',
      })));
    }
  }
  return folderBreadcrumbs;
};

export type FolderBreadcrumbsProps = {
  folderId: string | null | undefined;
  folders: Array<Folder>;
};

export function FolderBreadcrumbs({ 
  folderId,
  folders,
}: FolderBreadcrumbsProps) {
  const folderBreadcrumbs = getFolderBreadcrumbs(folders, folderId);

  return (
    <BreadcrumbsContainer>
      {folderBreadcrumbs.map((breadcrumb) => (
        <Crumb key={breadcrumb.path}>
          <StyledLink to={breadcrumb.path}>{breadcrumb.name}</StyledLink>
        </Crumb>
      ))}
    </BreadcrumbsContainer>
  );
}

function Breadcrumbs({ 
  tab,
  folderId,
  folders,
  skipTopLevel = false,
  skipTab = false,
}: BreadcrumbsProps) {
  const { t } = useTranslation();
  const breadcrumbs = [];

  if (!skipTopLevel) {
    breadcrumbs.push({
      path: '/',
      i18nKey: 'plumVillage' as const,
    });
  }
  if (!skipTab) {
    breadcrumbs.push({
      path: `/${tab}`,
      i18nKey: TABS[tab].i18nKey,
    });
  }
  const folderBreadcrumbs = getFolderBreadcrumbs(folders, folderId);

  return (
    <BreadcrumbsContainer>
      {breadcrumbs.map((breadcrumb) => (
        <Crumb key={breadcrumb.path}>
          <StyledLink to={breadcrumb.path}>{t(`breadcrumbs.${breadcrumb.i18nKey}.name`)}</StyledLink>
        </Crumb>
      ))}
      {folderBreadcrumbs.map((breadcrumb) => (
        <Crumb key={breadcrumb.path}>
          <StyledLink to={breadcrumb.path}>{breadcrumb.name}</StyledLink>
        </Crumb>
      ))}
    </BreadcrumbsContainer>
  );
}

export default Breadcrumbs;
