import React, { useCallback, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import elasticlunr from 'elasticlunr';

import 'normalize.css';

import './App.css';
import Page from './pages/Page';
import ItemView from './pages/ItemView';
import TopLevelView from './pages/TopLevelView';
import ListView from './pages/ListView';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Subtitles from './pages/Subtitles';
import SubtitleLookup from './pages/SubtitleLookup';
import { ContentContext, CurrentUserContext } from './contexts';
import { Content, Item } from './types';
import { GlobalSearchContext, createEmptyIndex, indexWithoutBlocking } from './search';
import { fetchContent } from './dataAccess';
import LatestUpdates from './pages/extras/LatestUpdates';
import { useTranslation } from 'react-i18next';

/**
 * On navigation, scrolls the window to the top.
 * We use a component here instead of a hook because getLocation is only available inside the BrowserRouter component.
 */
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
}

/**
 * React router does not currently provide an API to redirect to external websites.
 * Thus, a custom component is needed to manually redirect.
 * See https://github.com/remix-run/react-router/issues/8317#issuecomment-974577787
 */
function ExternalRedirect({ to }: { to: string }) {
  window.location.assign(to);

  return null;
}

function App() {
  const [content, setContent] = useState<Content | null>(null);
  const [globalSearchIndex, setGlobalSearchIndex] = useState<elasticlunr.Index<Item> | null>(null);
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState<User | null>(
    () => getAuth().currentUser,
  );
  const [authLoaded, setAuthLoaded] = useState<boolean>(false);

  onAuthStateChanged(getAuth(), (user) => {
    setCurrentUser(user);
    setAuthLoaded(true);
  });

  const startIndexing = useCallback((docs: Array<Item>) => {
    const newSearchIndex = createEmptyIndex();
    setGlobalSearchIndex(newSearchIndex);
    indexWithoutBlocking(docs, newSearchIndex, { indexing: true }, () => {
      // Clone the index to force an update to any results currently showing:
      setGlobalSearchIndex(elasticlunr.Index.load(newSearchIndex.toJSON()));
    });
  }, []);

  useEffect(() => {
    fetchContent().then((loadedContent) => {
      setContent(loadedContent);
      startIndexing(loadedContent.docs);
    });
  }, [startIndexing]);

  return (
    <CurrentUserContext.Provider value={{ user: currentUser, authLoaded }}>
      <ContentContext.Provider value={content}>
        <GlobalSearchContext.Provider value={globalSearchIndex}>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path="/login" element={<Page hideSearch hideFooter><Login /></Page>} />
              <Route path="/logout" element={<Page hideSearch hideFooter><Logout /></Page>} />
              <Route path="/subtitles" element={<Page hideSearch hideFooter wide><Subtitles /></Page>} />
              <Route path="/subs" element={<Page hideSearch hideFooter wide><SubtitleLookup /></Page>} />
              <Route path="/item/:id" element={<Page><ItemView /></Page>} />
              <Route path="/:tab/:folder" element={<Page><ListView key="folder" /></Page>} />
              <Route path="/:tab" element={<Page><ListView key="tab" /></Page>} />
              <Route path="/latest-updates" element={<Page><LatestUpdates /></Page>}  />
              <Route path="/extras/new-docs" element={<Navigate to="/latest-updates" replace={true} />}  />
              <Route path="/extras/retreats" element={<ExternalRedirect to='https://plumvillage.org/retreats/' />}  />
              <Route path="/extras/about-plum-village" element={<ExternalRedirect to='https://plumvillage.app/about-plum-village-a-buddhist-tradition-and-global-community-of-mindfulness-practice-centres/' />}  />
              <Route path="/extras/contact" element={<ExternalRedirect to='https://plumvillage.app/contact-us/' />}  />
              <Route path="/extras/*" element={<Page>{t('extras.description')}</Page>}  />
              <Route path="/" element={<Page><TopLevelView /></Page>} />
            </Routes>
          </BrowserRouter>
        </GlobalSearchContext.Provider>
      </ContentContext.Provider>
    </CurrentUserContext.Provider>
  );
}

export default App;
