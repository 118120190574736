import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { Item, ItemMetadata } from '../types';
import { LoadingWrapper, StyledSpinner } from '../components/common';
import Breadcrumbs, { BreadcrumbsProps } from '../components/Breadcrumbs';
import {
  loadFoldersForTab, loadItemById, loadItemBySlug, loadItemMetadataById,
} from '../dataAccess';
import { CurrentUserContext } from '../contexts';
import { getYouTubeId } from '../utils';
import { Trans, useTranslation } from 'react-i18next';
import { monthDayYear } from '../datetimeFormat';

const Wrapper = styled.article`
  h1 {
    margin-top: 30px;
    margin-bottom: 6px;
  }

  image,
  video {
    max-width: 100%;
    height: auto;
  }

  iframe {
    max-width: 100%;
    max-height: 60vw;
  }
  
  .bunny-video-embed {
    position: relative;
    padding-top: 56.25%;
    
    iframe {
      border: 0;
      position: absolute;
      top: 0;
      height: 100%;
      width:100%;
    }
  }

  audio {
    max-width: 100%;
    min-width: 80%;
  }
  
  /* This is the wrapper around the item we get back from the database */
  .item-content {
    line-height: 1.5;

    a {
      text-decoration: none;
      box-shadow: inset 0 -0.15em #ffd22a;
      transition: all .4s cubic-bezier(.15,.53,.35,1);

      :hover {
        box-shadow: inset 0 -0.4em #ffd22a;
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 1.5em;
    }

    /* This is the class attached to subheading elements. From https://quilljs.com/ */
    .ql-align-center {
      margin-bottom: 0;
    }
  }

  .preview-notice {
    color: darkred;
  }
  
  .caption-info {
    display: inline-block;
    margin-top: 1em;
    padding: 1em;
    color: #555;
    font-size: 0.8em;
    background-color: #fff;
    border-radius: 1em;
    
    a {
      color: #777;
    }
  }
`;

const Meta = styled.div`
  margin-bottom: 30px;

  span + span {
    margin-left: 10px;
    color: #777;
  }
`;

function ItemView() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<Item | null>(null);
  const [itemMetadata, setItemMetadata] = useState<ItemMetadata | null>(null);
  const [embedURL, setEmbedURL] = useState<string | null>(null);
  const [bunnyId, setBunnyId] = useState<string | null>(null);
  const [exists, setExists] = useState<boolean>(true);
  const [offline, setOffline] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [breadcrumbsProps, setBreadcrumbsProps] = useState<BreadcrumbsProps | null>(null);
  const [isPreview, setIsPreview] = useState<Boolean>(false);
  const currentUser = useContext(CurrentUserContext);

  const { id: itemIdOrSlug } = useParams<{ id: string }>();

  const fetchItem = useCallback(async () => {
    setLoading(true);
    setBunnyId(null);
    setEmbedURL(null);
    try {
      // Check if logged in as a content editor
      const hasEditPrivilege = currentUser.user
          && (await currentUser.user?.getIdTokenResult()).claims.edit_content;

      // We may load an item by either slug or ID.
      // Run the queries in parallel for faster loading time:
      const itemBySlugPromise = loadItemBySlug(itemIdOrSlug || '', !hasEditPrivilege);
      const { item: itemFromId, offline: isOffline } = await loadItemById(itemIdOrSlug || '');

      let loadedItem = itemFromId;

      setOffline(isOffline);

      if (!loadedItem) {
        loadedItem = await itemBySlugPromise;
      }

      if (!loadedItem) {
        setExists(false);
        setLoading(false);
        setBreadcrumbsProps(null);
        return;
      }

      loadItemMetadataById(loadedItem.id).then(({ metadata }) => {
        setItemMetadata(metadata);
      });

      const { folders: foldersForTab } = await loadFoldersForTab(loadedItem.tab, !hasEditPrivilege);
      const { folders: visibleFoldersForTab } = hasEditPrivilege
        ? await loadFoldersForTab(loadedItem.tab, true) : { folders: foldersForTab };
      const isFolderPublic = loadedItem && loadedItem.folder
        && visibleFoldersForTab.some((f) => f.id === (loadedItem && loadedItem.folder));

      // Parent folder does not exist / is not public:
      if (!hasEditPrivilege && loadedItem.folder && !isFolderPublic) {
        setExists(false);
        setLoading(false);
        setBreadcrumbsProps(null);
        return;
      }

      const vimeoMatches = loadedItem.hlsURL && loadedItem.hlsURL.match(/external\/([0-9]+).m3u8/);
      if (vimeoMatches && vimeoMatches[1]) {
        const vimeoID = vimeoMatches[1];
        setEmbedURL(`https://player.vimeo.com/video/${vimeoID}`);
      }

      const bunnyUrl = loadedItem.downloadURL || loadedItem.mediaURL;
      const bunnyMatches = bunnyUrl?.match(/https:\/\/vz-1057e2eb-385.b-cdn.net\/([a-f0-9\-]+)\//);
      if (bunnyMatches && bunnyMatches[1]) {
        setBunnyId(bunnyMatches[1]);
      }

      setItem(loadedItem);
      setExists(true);
      setIsPreview(loadedItem.visibility !== 'public' || Boolean(loadedItem.folder && !isFolderPublic));
      setBreadcrumbsProps({ tab: loadedItem.tab, folderId: loadedItem.folder, folders: foldersForTab });
    } catch (error) {
      setLoadingError(true);
    }
    setLoading(false);
  }, [itemIdOrSlug, currentUser]);

  useEffect(() => {
    fetchItem();
  }, [fetchItem]);

  if (loading) {
    return (
      <LoadingWrapper>
        <StyledSpinner />
        <p>{t('common.loading')}</p>
      </LoadingWrapper>
    );
  }

  if (offline && !item) {
    return <p>{t('listView.error.offline')}</p>;
  }

  if (!exists) {
    return <p>{t('itemView.error.itemDoesNotExist')}</p>;
  }

  if (loadingError) {
    return <p>{t('common.error.oopsSomethingWentWrong')}</p>;
  }

  if (!item) {
    return null;
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{t('common.title', { breadcrumb: item.title })}</title>
        {item.slug && (
          <link rel="canonical" href={`/item/${item.slug}`} />
        )}
      </Helmet>
      {breadcrumbsProps && <Breadcrumbs {...breadcrumbsProps} />}
      {item.type === 'video' && item.mediaURL && (
        <>
          {bunnyId ? (
            <div className='bunny-video-embed'>
              <iframe
                src={`https://iframe.mediadelivery.net/embed/127868/${bunnyId}?autoplay=false&loop=false&muted=false&preload=false`}
                loading="lazy"
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                allowFullScreen
                title={t('itemView.videoTitle', { title: item.title })}
              ></iframe>
            </div>
          ) : (
            <>
              {(embedURL ? (
                <iframe
                  src={embedURL}
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title={t('itemView.videoTitle', { title: item.title })}
                />
              ) : (
                <video controls>
                  <source src={item.mediaURL} type="video/mp4" />
                </video>
              ))}
            </>
          )}
        </>
      )}
      {isPreview && (
        <p className="preview-notice">
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <span role="img" aria-label={t('listView.noticeLabel')}>⚠️</span>
          {t('itemView.noticeContentTeam')}
        </p>
      )}
      <h1>{item.title}</h1>
      <Meta>
        {item.author && <span>{t('itemView.byline', { author: item.author })}</span>}
        {item.createdAt && <span>{monthDayYear(item.createdAt.seconds)}</span>}
      </Meta>
      {item.type === 'audio' && item.mediaURL && (
        <audio controls>
          <source src={item.mediaURL} type="audio/mpeg" />
        </audio>
      )}
      {/* eslint-disable-next-line react/no-danger */}
      {item.content && <div className="item-content" dangerouslySetInnerHTML={{ __html: item.content }} />}
      {itemMetadata?.sourceURL && getYouTubeId(itemMetadata.sourceURL) && (
        <span className="caption-info">
          <Trans
            i18nKey="itemView.youCanHelpUs"
            defaults="You can help us <anchor>caption or translate this video</anchor>"
            components={{
              anchor: <Link to={`/subs?yt=${getYouTubeId(itemMetadata.sourceURL)}`} />,
            }}
          />
        </span>
      )}
    </Wrapper>
  );
}

export default ItemView;
