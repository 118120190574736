import React from 'react';
import styled from 'styled-components';

import { StyledLink } from './common';
import { Folder } from '../types';
import { useTranslation } from 'react-i18next';

const ListItem = styled.li`
  background-color: #fff;
  border-radius: 54px;
  padding: 0.5em;
  margin-bottom: 0.5em;
  text-align: left;

  @media(max-width: 350px) {
    border-radius: 0;
    margin-left: -26px;
    margin-right: -26px;
  }
`;

const FolderPreview = styled(ListItem)`
  font-size: 1.2rem;
  line-height: 1.4em;
  display: flex;

  & img {
    vertical-align: middle;
    margin: auto 1em auto 0.5em;
  }

  @media(max-width: 450px) {
    font-size: 1.1rem;
    line-height: 24px;

    & img {
      border-radius: 0;
      width: 32px;
      height: 32px;
    }
  }

  @media(max-width: 350px) {
    font-size: 1rem;
  }

  &.private {
    opacity: 0.5;
    border: 1px dashed darkred;
  }
`;

const FolderTitle = styled.span`
  padding: 0.5em 0;
`;

const FolderInList = (
  { folder } : { folder: Folder },
) => {
  const { t } = useTranslation();
  return (
    <StyledLink to={`/${folder.tab}/${folder.slug}`} key={folder.id}>
      <FolderPreview className={folder.visibility}>
        <img
          src={`https://firebasestorage.googleapis.com/v0/b/plum-village.appspot.com/o/thumbs%2F${folder.thumbnail || 'default'}.png?alt=media`}
          width={54}
          height={54}
          alt={t('itemView.folderThumbnailDescription')}
        />
        <FolderTitle>{folder.title}</FolderTitle>
      </FolderPreview>
    </StyledLink>
  );
};

export default FolderInList;
