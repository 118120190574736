import React, { ReactElement } from 'react';
import styled from 'styled-components';
import audioIcon from '../images/audio.svg';
import textIcon from '../images/text.svg';
import videoIcon from '../images/video.svg';
import launchIcon from '../images/launch.svg';
import { BlogPostItem, Item } from '../types';
import { StyledLink } from './common';
import { useBreadcrumbsString } from '../hooks';
import { fromNow } from '../datetimeFormat';
import { useTranslation } from 'react-i18next';

const icons = {
  audio: audioIcon,
  text: textIcon,
  video: videoIcon,
  ['blog-post']: textIcon,
};

const RightIcon = styled.img`
  width: 1em;
`;

const ItemTypeIcon = styled.img`
  vertical-align: middle;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;

const ListItem = styled.li`
  background-color: #fff;
  border-radius: 54px;
  padding: 0.5em;
  margin-bottom: 0.5em;
  text-align: left;

  @media(max-width: 350px) {
    border-radius: 0;
    margin-left: -26px;
    margin-right: -26px;
  }
`;

const ItemPreview = styled(ListItem)`
  padding: 0.5em 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.private {
    opacity: 0.5;
    border: 1px dashed darkred;
  }
`;

const ItemTitle = styled.div`
  font-weight: bold;
`;
const ItemBreadcrumbs = styled.div`
  font-size: 0.8em;
  font-style: italic;
`;

const ItemAuthor = styled.div`
  font-style: italic;
  font-size: 0.9em;
`;
const ItemDuration = styled.span`
  font-size: 0.8em;
`;
const ItemDetails = styled.div`
  margin-top: 0.5em;
`;
const MetaSeparator = styled.span`
  color: '#999',
  font-size: 0.8em;
  margin-left: 0.4em;
  margin-right: 0.4em;
`;
const MetaText = styled.span`
  font-size: 0.8em;
`;

const separator = '•';

function getDurationMinutes(duration: number) {
  return duration
    ? `${Math.floor(duration / 60)}:${(duration % 60).toString().padStart(2, '0')}`
    : null;
}

function isBlogPostItem(item: Item | BlogPostItem): item is BlogPostItem {
  return item.type === 'blog-post';
}

const Author = ({ item } : { item: Item | BlogPostItem }): ReactElement | null => {
  const { t } = useTranslation();
  const isBlogPost = isBlogPostItem(item);
  if (isBlogPost) {
    const url = new URL(item.link);
    return (
      <ItemAuthor>
        {t('itemView.blogPostFrom', { blogUrl: url.hostname })}
      </ItemAuthor>
    );
  } else if (item.author) {
    return (
      <ItemAuthor>
        {t('itemView.byline', { author: item.author })}
      </ItemAuthor>
    );
  }
  return null;
};

const ItemInList = (
  { item, showBreadcrumbs } : { item: Item | BlogPostItem, showBreadcrumbs: boolean },
) => {
  const { t } = useTranslation();
  const breadcrumbs = useBreadcrumbsString({ item, showBreadcrumbs });
  const isBlogPost = isBlogPostItem(item);
  const blogPostProps = isBlogPost ? {
    target: '_blank',
    rel: 'noopener noreferrer',
  } : {};
  const link = isBlogPost ? item.link : `/item/${item.slug}`;

  return (
    <StyledLink to={link} key={item.id} {...blogPostProps}>
      <ItemPreview className={!isBlogPost ? item.visibility : 'public'}>
        <div>
          {showBreadcrumbs && (
            <ItemBreadcrumbs>
              {breadcrumbs}
            </ItemBreadcrumbs>
          )}
          <ItemTitle>{item.title}</ItemTitle>
            <Author item={item} />
          <ItemDetails>
            <ItemTypeIcon src={icons[item.type]} alt={item.type} width={16} height={16} />
            <ItemDuration>{getDurationMinutes(item.duration)}</ItemDuration>
            {item.createdAt && (
              <>
                <MetaSeparator>{separator}</MetaSeparator>
                <MetaText>
                  {fromNow(item.createdAt.seconds)}
                </MetaText>
              </>
            )}
          </ItemDetails>
        </div>
        {isBlogPost && <RightIcon src={launchIcon} alt={t('itemView.openInNewTab')} width={16} height={16} />}
      </ItemPreview>
    </StyledLink>
  );
};

export default ItemInList;
