import { useContext, useMemo } from 'react';
import { BlogPostItem, Folder, Item } from './types';
import { ContentContext } from './contexts';

const capitalize = (str: string) => str.replace(/^\w/, (c) => c.toUpperCase());

/*
 * Returns of an array of ancestor folders from top to bottom (direct parent).
 * Empty if the folder is top level.
 */
export function getAncestors(folder: Folder, foldersById: { [id: string]: Folder }): Folder[] {
  if (folder && folder.parent) {
    const parentFolder = foldersById[folder.parent];
    if (parentFolder) {
      return [parentFolder, ...getAncestors(parentFolder, foldersById)];
    }
  }
  return [];
}

type BreadcrumbProps = {
  item: Item | BlogPostItem,
  showBreadcrumbs: boolean,
};

export const useBreadcrumbsString = ({ item, showBreadcrumbs }: BreadcrumbProps): string => {
  const content = useContext(ContentContext);

  const breadcrumbs = useMemo<string>(() => {
    if (!showBreadcrumbs || !content) {
      return '';
    }

    const breadcrumbsArray = [capitalize(item.tab)];

    const { foldersById } = content;
    const folder = foldersById[item.folder];
    if (folder) {
      breadcrumbsArray.push(...getAncestors(folder, foldersById).map((f) => f.title));
      breadcrumbsArray.push(folder.title);
    }

    return breadcrumbsArray.join(' > ');
  }, [content, item, showBreadcrumbs]);
  return breadcrumbs;
};
