/**
 * Corresponds to "NewDocs" in plumvillage-app repo.
 * Lots of code is copied or adapted from there.
 * This page also contains related logic from ListView (located in this repo).
 */
import {
  useState, useEffect, useCallback, useContext, useRef, useMemo,
} from 'react';
import firebase from 'firebase/compat/app';
import { Item, BlogPost, Results, BlogPostItem } from '../../types';
import {
  Listing, LoadingWrapper, StyledSpinner,
} from '../../components/common';
import { CustomBreadcrumbs, Breadcrumb } from '../../components/Breadcrumbs';
import { ContentContext } from '../../contexts';
import ItemInList from '../../components/ItemInList';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const LATEST_UPDATES_BREADCRUMBS: Breadcrumb[] = [{
  path: '/',
  i18nKey: 'plumVillage',
}, {
  path: '/latest-updates',
  i18nKey: 'latestUpdates',
}];

const blogPostToItem = (blogPost: BlogPost): BlogPostItem => {
  const {
    id, title, link, duration, publishedAt, updatedAt,
  } = blogPost;

  return {
    id,
    title,
    slug: '',
    content: '',
    author: '',
    folder: '',
    mediaURL: '',
    link,
    order: 0,
    tab: 'extras',
    tags: '',
    type: 'blog-post',
    duration,
    waveformData: '',
    visibility: 'public',
    createdAt: publishedAt,
    updatedAt,
  };
};

export function compareByCreatedAt(
  a: { createdAt?: firebase.firestore.Timestamp },
  b: { createdAt?: firebase.firestore.Timestamp },
): number {
  if ((a.createdAt?.seconds || 0) > (b.createdAt?.seconds || 0)) {
    return -1;
  } if ((a.createdAt?.seconds || 0) < (b.createdAt?.seconds || 0)) {
    return 1;
  }
  return 0;
}

function useDocsQuery<Entity extends { id: string, [key: string]: unknown }>(
  query: firebase.firestore.Query | null,
): Results<Entity> {
  const [loading, setLoading] = useState(true);
  const [isOffline, setIsOffline] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [docs, setDocs] = useState<Entity[]>([]);
  const [retryCount, setRetryCount] = useState(0);

  const isMounted = useRef(true);

  const retry = useCallback(() => setRetryCount((i) => i + 1), []);

  useEffect(() => {
    isMounted.current = true;

    setLoading(true);
    setIsOffline(false);
    setLoadingError(false);
    setDocs([]);

    if (!query) {
      return undefined;
    }

    const unsubscribe = query.onSnapshot((snapshot) => {
      if (!isMounted.current) {
        return;
      }

      if (snapshot.metadata.fromCache) {
        setIsOffline(true);
      } else {
        setIsOffline(false);
      }

      const entities: Entity[] = [];

      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        const entity = {
          id: doc.id,
          ...data,
        } as unknown as Entity;
        entities.push(entity);
      });

      setDocs(entities);
      setLoading(false);
    }, (error) => {
      console.error('Error while listening to QuerySnapshot events', error);
      setLoadingError(true);
      setLoading(false);
    });

    return () => {
      isMounted.current = false;
      unsubscribe();
    };
  }, [isOffline, query, retryCount]);

  return {
    loading,
    isOffline,
    loadingError,
    docs,
    retry,
  };
}

function LatestUpdates() {
  const content = useContext(ContentContext);
  const { t } = useTranslation();

  const blogPostsRef = useMemo(() => firebase.firestore().collection('news'), []);
  const {
    loading,
    docs: allBlogPosts,
  } = useDocsQuery<BlogPost>(blogPostsRef);
  // TODO #28 i18n
  const primaryLanguage = 'en';

  // latestItems may consist of recent text/audio/video items + recent blog posts
  const latestItems = useMemo((): Array<Item | BlogPostItem> => {
    if (!content) {
      return [];
    }
    const { docs, foldersById } = content;

    const blogPosts = allBlogPosts.filter(
      (blogPost) => blogPost.alwaysShowEnglish || blogPost.lang === primaryLanguage,
    ).map(blogPostToItem);

    // latest app items
    const items = docs
      .sort(compareByCreatedAt)
      .filter(doc => {
        const primaryFolder = foldersById[doc.folder];

        if (!primaryFolder) {
          return null;
        }

        const folderLang = primaryFolder.lang || 'en';

        // Show updates in user's language plus English (since non-English content is limited)
        return folderLang === primaryLanguage || folderLang === 'en';
      })
      .filter(doc => doc && !doc.hideFromLatestUpdates)
      .slice(0, (20 - blogPosts.length));

    return [...items, ...blogPosts].sort(compareByCreatedAt);
  }, [content, allBlogPosts, primaryLanguage]);

  if (loading) {
    return (
      <LoadingWrapper>
        <StyledSpinner />
        <p>{t('common.loading')}</p>
      </LoadingWrapper>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {t('latestUpdates.title')}
        </title>
      </Helmet>
      <CustomBreadcrumbs breadcrumbs={LATEST_UPDATES_BREADCRUMBS} />
      <h1>{t('latestUpdates.heading')}</h1>
      <p>{t('breadcrumbs.latestUpdates.description')}</p>
      <Listing>
        {latestItems && latestItems.length > 0 && latestItems.map((item) => (
          <ItemInList key={item.id} item={item as Item} showBreadcrumbs={false} />
        ))}
      </Listing>
    </>
  );
}

export default LatestUpdates;
