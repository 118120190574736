import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { getAuth, onAuthStateChanged, EmailAuthProvider, GoogleAuthProvider, User } from 'firebase/auth';
import { Trans, useTranslation } from 'react-i18next';

import { LoadingWrapper, StyledSpinner } from '../components/common';
import ky from 'ky';
import { firebaseFunctionsBaseUrl } from '../constants';

const Wrapper = styled.div`
  .avatar {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  
  p {
    line-height: 1.5em;
  }
  
  hr {
    width: 50%;
    color: #bbb;
    min-width: 200px;
  }
`;
const RetryButton = styled.button`
  display: inline;
  background: none;
  color: #e58a2f;
  border: 0;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
`;
const ContinueButton = styled.button`
  background-color: #fff;
  border: 2px solid #33bb55;
  color: #119933;
  font-weight: bold;
  border-radius: 1em;
  padding: 0.5em 1em;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  color: #777;
`;

// Gets the i18n key for emailInfo
const getProviderViaNameKey = (user: User) => {
  const userInfos = user.providerData;
  if (userInfos[0]) {
    const provider = userInfos[0].providerId;
    switch (provider) {
      case 'google.com':
        return 'google';
      case 'apple.com':
        return 'apple';
      case 'email':
        return 'email';
    }
  }
  return 'default';
};

function Login() {
  const [user, setUser] = useState<User | null>();
  const [loading, setLoading] = useState(true);
  const [ssoAuthError, setSSOAuthError] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isSso = window.location.search.includes('sso=');

  const ssoAuth = useCallback(async () => {
    if (!user) {
      return;
    }

    setLoading(true);
    setSSOAuthError(false);

    try {
      const idToken = await user.getIdToken();

      const response = await ky.post(`${firebaseFunctionsBaseUrl}/discourse-session`, {
        json: { idToken },
        credentials: 'include',
        mode: 'cors',
      });
      const { session } = (await response.json()) as { session: string };

      const { redirectURL } = await ky
        .get(`${firebaseFunctionsBaseUrl}/discourse-sso${window.location.search}&session=${encodeURIComponent(session)}`, {
          mode: 'cors',
          credentials: 'include',
        }).json() as { redirectURL: string };

      const redirectURLObj = new URL(redirectURL);
      if (redirectURLObj.hostname !== window.location.hostname) {
        window.location.href = redirectURL;
      } else {
        throw new Error('Redirect loop');
      }
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      setSSOAuthError(true);
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (authUser) => {
      setUser(authUser);

      if (authUser && !isSso) {
        navigate('/');
      } else {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, [navigate, isSso, ssoAuth]);

  const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [
      EmailAuthProvider.PROVIDER_ID,
      GoogleAuthProvider.PROVIDER_ID,
      { provider: 'apple.com' },
    ],
    privacyPolicyUrl: 'https://plumvillage.app/privacy/',
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <StyledSpinner />
        <p>{t('common.loading')}</p>
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{t('login.title')}</title>
      </Helmet>
      {user ? (
        <>
          {isSso ? (
            <>
              {ssoAuthError ? (
                <div>
                  <h1>{t('common.error.oops')}</h1>
                  <p>
                    <Trans
                      i18nKey="login.error.retryMessage"
                      defaults="Something went wrong, <retry>retry</retry>"
                      components={{
                        retry: <RetryButton onClick={ssoAuth} />,
                      }}
                    />
                  </p>
                  <p>
                    <Trans
                      i18nKey="login.error.contactUsLink"
                      defaults="If the error reoccurs, please <anchor>contact us</anchor>"
                      components={{
                        anchor: <a href='https://plumvillage.app/contact-us/' />,
                      }}
                    />
                  </p>
                </div>
              ) : (
                <>
                  <h2>{t('common.plumVillageApp')}</h2>
                  {user.photoURL && <p><img className='avatar' src={user.photoURL} alt={user.displayName || ''} /></p>}
                  <p>
                    <Trans
                      i18nKey="common.loginInfo"
                      defaults="Logged in as <user>{{user}}</user>"
                      values={{ user: user.displayName }}
                      components={{ user: <strong /> }}
                    />
                    <br/>
                    <small>
                      <Trans
                        i18nKey={`login.emailInfo.${getProviderViaNameKey(user)}`}
                        defaults="<i>{{email}}</i>"
                        values={{ email: user.email }}
                      />
                    </small>
                  </p>
                  <br/>
                  <hr/>
                  <p>
                    <Trans
                      i18nKey="login.communityForum"
                      defaults="Logging into<br/><strong>Plum Village App Sangha (community forum)</strong>"
                    />
                  </p>
                  <p><ContinueButton onClick={ssoAuth}>{t('login.continue')}</ContinueButton></p>
                  <hr/>
                  <p><small>{t('login.error.wrongUser')} <StyledLink to='/logout'>{t('common.logout')}</StyledLink></small></p>
                </>
              )}
            </>
          ) : (
            <p>{t('login.successfulRedirectMessage')}</p>
          )}
        </>
      ) : (
        <>
          <h1>{t('login.heading')}</h1>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={getAuth()}
          />
          {isSso && (
            <>
              <br/>
              <hr/>
              <p>
                <Trans
                  i18nKey="login.communityForum"
                  defaults="Logging into<br/><strong>Plum Village App Sangha (community forum)</strong>"
                />
              </p>
              <hr/>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
}

export default Login;
