/**
 * LatestUpates currently copies code from here
 * A refactor would be needed to be able to extract common logic from this and LatestUpates
 */
import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Folder, Item, Tab } from '../types';
import { TABS } from '../constants';
import {
  Listing, LoadingWrapper, StyledSpinner,
} from '../components/common';
import Breadcrumbs, { BreadcrumbsProps } from '../components/Breadcrumbs';
import { loadFolderBySlug, loadFoldersForTab, loadDocsByFolder } from '../dataAccess';
import { CurrentUserContext } from '../contexts';
import ItemInList from '../components/ItemInList';
import FolderInList from '../components/FolderInList';
import { useTranslation } from 'react-i18next';

const PreviewNotice = styled.p`
  color: darkred;
`;

const Intros = styled.div`
  margin-bottom: 2em;
`;

const DescriptionToggle = styled.button`
  background: none;
  color: #000;
  font-weight: 700;
  border: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

function ListView() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState<boolean>(false);
  const [folders, setFolders] = useState<Array<Folder> | null>(null);
  const [currentFolder, setCurrentFolder] = useState<Folder | null>(null);
  const [items, setItems] = useState<Array<Item> | null>(null);
  const [exists, setExists] = useState<boolean>(true);
  const [offline, setOffline] = useState<boolean>(false);
  const [isPreview, setIsPreview] = useState<Boolean>(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [breadcrumbsProps, setBreadcrumbsProps] = useState<BreadcrumbsProps | null>(null);
  const currentUser = useContext(CurrentUserContext);
  const { t } = useTranslation();

  const { tab, folder: folderSlug } = useParams<{ tab: Tab, folder?: string }>();

  const toggleDescription = useCallback(() => {
    setIsDescriptionVisible(!isDescriptionVisible);
  }, [isDescriptionVisible]);

  const fetchFoldersOrItems = useCallback(async () => {
    if (!currentUser.authLoaded) {
      return false;
    }

    // Check tab exists
    if (!tab || !Object.values(TABS).map((tabObj) => tabObj.slug).includes(tab)) {
      setExists(false);
      setLoading(false);
      return;
    }

    // Check if logged in as a content editor
    const hasEditPrivilege = currentUser.user
        && (await currentUser.user.getIdTokenResult()).claims.edit_content;
    try {
      const loadedFolder: Folder | null = folderSlug
        ? await loadFolderBySlug(folderSlug, tab, !hasEditPrivilege) : null;

      if (folderSlug && !loadedFolder) {
        // Folder does not exist
        setExists(false);
        setLoading(false);
        setBreadcrumbsProps(null);
        return;
      }

      const folderId: string | null = loadedFolder?.id || null;

      const {
        folders: foldersForTab, offline: isOffline,
      } = await loadFoldersForTab(tab, !hasEditPrivilege);
      setOffline(isOffline);

      const { folders: visibleFoldersForTab } = hasEditPrivilege
        ? await loadFoldersForTab(tab, true) : { folders: foldersForTab };

      const isFolderPublic = loadedFolder && loadedFolder.parent
          && visibleFoldersForTab.some((f) => f.id === (loadedFolder && loadedFolder.parent));

      // Parent folder does not exist / is not public:
      if (!hasEditPrivilege && loadedFolder && loadedFolder.parent && !isFolderPublic) {
        setExists(false);
        setLoading(false);
        setBreadcrumbsProps(null);
        return;
      }

      let foldersForPage;
      if (folderId) {
        setCurrentFolder(foldersForTab.find((f) => f.id === folderId) || null);
        foldersForPage = foldersForTab.filter((folder) => folder.parent === folderId);
      } else {
        foldersForPage = foldersForTab.filter((folder) => !folder.parent);
      }
      setFolders(foldersForPage);
      setExists(true);

      if (folderId && foldersForPage.length === 0) {
        // We only show items if there are no sub-folders (this behaviour matches the mobile app)
        setItems(await loadDocsByFolder(folderId, !hasEditPrivilege));
      } else {
        setItems(null);
      }
      setIsPreview(Boolean(loadedFolder
          && (loadedFolder.visibility !== 'public' || Boolean(loadedFolder.parent && !isFolderPublic))));

      setBreadcrumbsProps({ tab, folderId, folders: foldersForTab });
    } catch (error) {
      setLoadingError(true);
    }

    setLoading(false);
  }, [tab, folderSlug, currentUser]);

  useEffect(() => {
    fetchFoldersOrItems();
  }, [fetchFoldersOrItems]);

  if (loading) {
    return (
      <LoadingWrapper>
        <StyledSpinner />
        <p>{t('common.loading')}</p>
      </LoadingWrapper>
    );
  }

  if (offline && !folders) {
    return <p>{t('listView.error.offline')}</p>;
  }

  if (!exists || !tab) {
    return <p>{t('listView.error.pageNotFound')}</p>;
  }

  if (loadingError) {
    return <p>{t('common.error.oopsSomethingWentWrong')}</p>;
  }

  const breadcrumb = currentFolder ? currentFolder.title : t(`breadcrumbs.${TABS[tab].i18nKey}.name`);

  let intro;
  let description;
  if (currentFolder) {
    intro = currentFolder?.intro;
    description = currentFolder?.description;
  } else {
    const translatedTabIntro = t(`breadcrumbs.${TABS[tab].i18nKey}.description`);
    intro = `<p>${translatedTabIntro}</p>`;
  }
  const toggleText = isDescriptionVisible ? t('listView.hideDescription') : t('listView.showDescription');

  return (
    <>
      <Helmet>
        <title>
          {t('common.title', { breadcrumb })}
        </title>
      </Helmet>
      {breadcrumbsProps && <Breadcrumbs {...breadcrumbsProps} />}
      <h1>{currentFolder ? currentFolder.title : t(`breadcrumbs.${TABS[tab].i18nKey}.name`)}</h1>
      <Intros>
        {/* eslint-disable-next-line react/no-danger */}
        {intro && <div dangerouslySetInnerHTML={{ __html: intro }} />}
        {/* eslint-disable-next-line react/no-danger */}
        {description && isDescriptionVisible && <div dangerouslySetInnerHTML={{ __html: description }} />}
        {description && <DescriptionToggle onClick={toggleDescription}>{toggleText}</DescriptionToggle>}
      </Intros>
      {isPreview && (
        <PreviewNotice>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <span role="img" aria-label={t('listView.noticeLabel')}>⚠️</span>
          {t('listView.noticeContentTeam')}
        </PreviewNotice>
      )}
      <Listing>
        {folders && folders.length > 0 && folders.map((folder) => (
          <FolderInList key={folder.id} folder={folder} />
        ))}
        {items && items.length > 0 && items.map((item) => (
          <ItemInList key={item.id} item={item} showBreadcrumbs={false} />
        ))}
      </Listing>
    </>
  );
}

export default ListView;
