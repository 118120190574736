import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import web from './locales/en/web.json';
import doNotTranslate from './locales/en/doNotTranslate.json';

// Default namespace
export const defaultNS = 'web';

// the translations
export const resources = {
  en: {
    web,
    // We don't expect the content here to need translation.
    doNotTranslate,
  },
} as const;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // Since we are using a language detector, do not define the lng option. Detection would be overridden
    fallbackLng: 'en',
    ns: ['web', 'doNotTranslate'],
    defaultNS,
    resources,
    detection: {
      order: ['path', 'navigator', 'htmlTag', 'querystring'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;