import React from 'react';
import { createRoot } from 'react-dom/client';
import './i18n';
// import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';

import App from './App';
import './initFirebase';
import * as serviceWorker from './serviceWorker';

// const matomoInstance = createInstance({
//   urlBase: 'https://stats.plumvillage.org',
//   siteId: 2,
//   disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
// });

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<React.StrictMode>
  {/*<MatomoProvider value={matomoInstance}>*/}
    <App />
  {/*</MatomoProvider>*/}
</React.StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
